import basicStatus from '@/@fake-db/data/basicStatus.json'
import courseGroup from '@/api/courseManagement/courseGroup'
import courseList from '@/api/courseManagement/courseList'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import profile from '@/api/profile'

import store from '@/store'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useCourseList() {
  const dataTableList = ref([])
  const totalDataTableList = ref(0)
  const segmentId = ref(0)
  const totalPage = ref(0)
  const showCost = ref(false)
  const loading = ref(false)
  const options = ref({})
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'course_id_pri',

      width: 70,
    },
    {
      text: i18n.t('code'),
      value: 'course_id',

      width: 100,
    },
    {
      text: i18n.t('course_name'),
      value: 'course_name',

      width: 300,
    },
    {
      text: i18n.t('category'),
      value: 'coursegroup_name',

      width: 120,
    },
    {
      text: i18n.t('course_price'),
      value: 'course_price',

      align: 'end',
      width: 150,
    },
    {
      text: i18n.t('doctor_fee'),
      value: 'course_costdoctor',

      align: 'end',
      width: 120,
    },
    {
      text: i18n.t('staff_costs'),
      value: 'course_costuser',

      align: 'end',
      width: 180,
    },

    {
      text: i18n.t('cost_drug_equipment'),
      value: 'course_total',

      align: 'end',
      width: 150,
    },
    {
      text: i18n.t('type'),
      value: 'course_type',

      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('times_courses'),
      value: 'course_amount',

      align: 'center',
      width: 130,
    },
    {
      text: i18n.t('date_range'),
      value: 'course_expday',

      align: 'center',
      width: 120,
    },
    {
      text: i18n.t('status'),
      value: 'course_status_id',

      align: 'center',
      width: 120,
    },

    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      align: 'center',
      width: 160,
    },
  ])

  const { courseListGet, courseStatusUpdate } = courseList
  const { courseGroupList } = courseGroup

  // search valiable
  const searchtext = ref('')
  const coursegroup_id = ref('')
  const coursegroup_list = ref([])
  const course_status_id = ref(1)
  const statusList = ref(basicStatus.data)

  const isAddNewCourse = ref(false)

  const isEditCourseLimitTimes = ref(false)
  const isEditCourseLimitDay = ref(false)
  const isEditCourseLimitDrug = ref(false)
  const dataEdit = ref({})

  const course_status_id_update = ref('')
  const course_id_pri = ref('')
  const processStatus = ref(false)
  const isUpdateStatus = ref(false)
  const isImportFile = ref(false)
  const statusImport = ref(1)

  courseGroupList({
    searchtext: '',
    coursegroup_status_id: '1',
  }).then(res => {
    coursegroup_list.value = res
    coursegroup_list.value.unshift({
      coursegroup_id: '',
      coursegroup_name: i18n.t('all'),
    })
  })

  onMounted(() => {
    fetchDataTable()
  })
  profile().then(res => {
    showCost.value = res.user_data_customers == '0'
  })
  const fetchDataTable = () => {
    loading.value = true
    courseListGet({
      searchtext: searchtext.value,
      coursegroup_id: coursegroup_id.value,
      course_status_id: course_status_id.value,
      active_page: options.value.page,
      per_page: options.value.itemsPerPage == -1 ? '0' : options.value.itemsPerPage,
    }).then(res => {
      const { data, count, segment, count_of_page } = res
      totalDataTableList.value = count
      totalPage.value = count_of_page
      dataTableList.value = data
      segmentId.value = segment
      loading.value = false
    })
  }

  const updateStatus = () => {
    processStatus.value = true
    courseStatusUpdate({
      course_id_pri: course_id_pri.value,
      course_status_id: course_status_id_update.value == '1' ? '2' : '1',
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      processStatus.value = false
      isUpdateStatus.value = false
      fetchDataTable()
    })
  }
  watch([searchtext, coursegroup_id, course_status_id, options], (newvalue, oldvalue) => {
    if (newvalue[0] !== oldvalue[0] || newvalue[1] !== oldvalue[1] || newvalue[2] !== oldvalue[2]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    statusImport,
    isImportFile,
    course_status_id_update,
    course_id_pri,
    processStatus,
    isUpdateStatus,
    updateStatus,
    showCost,
    isEditCourseLimitDrug,
    isEditCourseLimitDay,
    isEditCourseLimitTimes,
    dataEdit,
    totalDataTableList,
    totalPage,
    columns,
    dataTableList,
    segmentId,
    loading,
    options,
    footer,
    searchtext,
    coursegroup_id,
    coursegroup_list,
    course_status_id,
    statusList,
    isAddNewCourse,
    fetchDataTable,
  }
}

<template>
  <div>
    <v-dialog
      v-model="isImportFile"
      persistent
      max-width="500"
    >
      <v-card>
        <v-card-title class="d-flex justify-content-between align-middle">
          {{
            statusImport == 1
              ? $t("import_files_set_csv")
              : statusImport == 2
                ? $t("import_files_unlimited_csv")
                : $t("import_files_normal_csv")
          }}
          <v-spacer></v-spacer>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <a :href="statusImport==1?courset3_form:statusImport==2?courset2_form:course_form">
                <v-btn
                  color="primary"
                  class="ma-2"
                  v-bind="attrs"
                  x-small
                  fab
                  v-on="on"
                >
                  <v-icon dark>{{ icons.mdiCloudDownload }}</v-icon>
                </v-btn>
              </a>
            </template>
            <span>{{ $t("download_form") }}</span>
          </v-tooltip>
        </v-card-title>
        <v-form
          ref="formImportFile"
          @submit.prevent="sendFile"
        >
          <v-card-text>
            <v-autocomplete
              v-model="coursegroup_id"
              outlined
              dense
              :label="$t('course_category')"
              :rules="[required]"
              :items="coursegroup_data"
              :no-data-text="$t('no_information')"
              item-text="coursegroup_name"
              item-value="coursegroup_id"
            ></v-autocomplete>
            <v-file-input
              v-model="files"
              :label="$t('choose_a_file')"
              show-size
              dense
              outlined
              classes="mt-3"
              accept=".csv"
              @change="checkFile"
            ></v-file-input>
            <span class="ml-3">Log</span> <span
              v-if="checkLoading"
              class="primary--text"
            >{{ $t('checkDataLoading') }}</span>
            <v-sheet
              outlined
              rounded="lg"
              height="100"
            >
              <v-virtual-scroll
                :items="dataCheckFile.data"
                :item-height="20"
                height="100"
              >
                <template v-slot:default="{ item,index }">
                  <span
                    class="text-sm ml-2"
                    :class="item.status_id==0?'error--text':'success--text'"
                  > {{ index+1 }}) {{ item.status_id==0? item.status_message :`ข้อมูลถูกต้อง (${item.course_id} ${item.course_name})` }} <v-icon
                    small
                    :color="item.status_id==0?'error':'success'"
                  >{{ item.status_id==0? icons.mdiClose:icons.mdiCheck }}</v-icon> </span>
                </template>
              </v-virtual-scroll>
            </v-sheet>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              :loading="loading"
              :disabled="loading || !files||dataCheckFile.checkfalse>=1 || checkLoading"
              type="submit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-import-file',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCloudDownload } from '@mdi/js'
import courseGroup from '@/api/courseManagement/courseGroup'
import course_form from '@/assets/download_files/course_form.csv'
import courset2_form from '@/assets/download_files/courset2_form.csv'
import courset3_form from '@/assets/download_files/courset3_form.csv'
import { required } from '@/@core/utils/validation'
import courseList from '@/api/courseManagement/courseList'
import store from '@/store'
import checkImportFile from '@/api/checkImportFile'

export default {
  model: {
    prop: 'isImportFile',
    event: 'update:is-import-file',
  },
  props: {
    isImportFile: {
      type: Boolean,
      default: false,
    },
    statusImport: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const files = ref(null)
    const coursegroup_data = ref([])
    const coursegroup_id = ref('')
    const formImportFile = ref(null)
    const loading = ref(false)
    const checkLoading = ref(false)
    const dataCheckFile = ref({})

    const { courseGroupList } = courseGroup
    const { courseImportFile } = courseList

    const getCourseGroupList = () => {
      courseGroupList({
        searchtext: '',
        coursegroup_status_id: 1,
      }).then(res => {
        coursegroup_data.value = res
        coursegroup_id.value = res[res.length - 1].coursegroup_id
      })
    }

    const sendFile = () => {
      const isFormValid = formImportFile.value.validate()
      if (!isFormValid) return
      loading.value = true

      let path = ''
      if (props.statusImport == 1) {
        path = 'course/import3'
      } else if (props.statusImport == 2) {
        path = 'course/import2'
      } else if (props.statusImport == 3) {
        path = 'course/import1'
      }
      const formData = new FormData()
      formData.append('coursegroup_id', coursegroup_id.value)
      formData.append('file', files.value)
      courseImportFile(path, formData).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
      })
      emit('update:is-import-file', false)
      emit('onSend')
      files.value = null
      loading.value = false
    }

    watch(() => props.isImportFile, value => {
      if (value) {
        getCourseGroupList()
        files.value = null
      }
    })
    const checkFile = () => {
      checkLoading.value = true
      let path = ''
      if (props.statusImport == 1) {
        path = 'course/checkimport3'
      } else if (props.statusImport == 2) {
        path = 'course/checkimport2'
      } else if (props.statusImport == 3) {
        path = 'course/checkimport1'
      }
      const formData = new FormData()
      formData.append('coursegroup_id', coursegroup_id.value)
      formData.append('file', files.value)
      checkImportFile.checkImportFileCourse(path, formData).then(res => {
        dataCheckFile.value = res
        checkLoading.value = false
      })
    }

    return {
      loading,
      checkLoading,
      dataCheckFile,
      formImportFile,
      sendFile,
      files,
      course_form,
      courset2_form,
      courset3_form,
      required,
      coursegroup_data,
      checkFile,
      coursegroup_id,
      icons: {
        mdiCloudDownload,
      },
      getCourseGroupList,

    }
  },
}
</script>

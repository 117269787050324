<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        {{ $t('course_list') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="me-2 d-none d-md-block"
          @click="isAddNewCourse=true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_course') }}
        </v-btn>
        <v-btn
          color="primary"
          class="me-2 d-block d-md-none"
          fab
          icon
          outlined
          @click="isAddNewCourse=true"
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
        <v-menu
          offset-y
          open-on-hover
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="secondary"
              outlined
              class="d-none d-md-block"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon class="me-1">
                {{ icons.mdiFileImportOutline }}
              </v-icon>
              {{ $t("import_files") }}
              <v-icon
                right
              >
                {{ icons.mdiMenuDownOutline }}
              </v-icon>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              fab
              icon
              class="d-block d-md-none"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                {{ icons.mdiFileImportOutline }}
              </v-icon>
            </v-btn>
          </template>
          <v-list
            nav
            dense
          >
            <v-list-item @click="statusImport = 1;isImportFile = true">
              <v-list-item-title>
                {{ $t("import_files_set_csv") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="statusImport = 2;isImportFile = true">
              <v-list-item-title>
                {{ $t("import_files_unlimited_csv") }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="statusImport = 3;isImportFile = true">
              <v-list-item-title>
                {{ $t("import_files_normal_csv") }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-row class="px-2">
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            :label="$t('search')"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-autocomplete
            v-model.trim="coursegroup_id"
            :items="coursegroup_list"
            :label="$t('Course_Gategory')"
            item-text="coursegroup_name"
            outlined
            dense
            item-value="coursegroup_id"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="py-0"
          lg="4"
        >
          <v-select
            v-model.trim="course_status_id"
            :items="statusList"
            :label="$t('status')"
            :item-text="$i18n.locale"
            outlined
            dense
            item-value="value"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :server-items-length="totalDataTableList"
        :footer-props="footer"
        :loading="loading"
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.course_id_pri`]="{ index }">
          {{ segmentId + index + 1 }}
        </template>
        <template v-slot:[`item.course_id`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'courseDetail', params: { id: item.course_id_pri } }"
          >
            {{ item.course_id }}
          </router-link>
        </template>
        <template v-slot:[`item.course_price`]="{ item }">
          {{ item.course_price | formatPrice }}
        </template>
        <template v-slot:[`item.course_costdoctor`]="{ item }">
          {{ item.course_costdoctor | formatPrice }}
        </template>
        <template v-slot:[`item.course_costuser`]="{ item }">
          {{ item.course_costuser | formatPrice }}
        </template>
        <template v-slot:[`item.course_total`]="{ item }">
          <router-link
            v-if="item.drug && item.drug.length <= 0 "
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'courseDetail', params: { id: item.course_id_pri } }"
          >
            {{ item.course_total | formatPrice }}
          </router-link>

          <router-link
            v-if="item.drug && item.drug.length > 0"
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'courseDetail', params: { id: item.course_id_pri } }"
          >
            <v-tooltip
              color="#212121"
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="primary--text"
                  v-bind="attrs"
                  v-on="on"
                >{{  showCost ? (item.course_total | formatPrice) :'xxx' }}</span>
              </template>

              <ol class="py-0 my-0 mx-0 pl-2 pr-0">
                <li
                  v-for="(i, index) in item.drug"
                  :key="index"
                >
                  {{ i.drug_name }} *{{ i.coursedrug_amount }}
                </li>
              </ol>
            </v-tooltip>
          </router-link>
          <router-link
            v-else
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'courseDetail', params: { id: item.course_id_pri } }"
          >
           {{ showCost ? '0.00':'xxx' }} 
          </router-link>
        </template>
        <template v-slot:[`item.course_type`]="{ item }">
          <CourseType :type="item.course_type" />
        </template>
        <template v-slot:[`item.course_status_id`]="{ item }">
          <StatusBlock :status="item.course_status_id" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <router-link
            class="font-weight-medium text-decoration-none"
            :to="{ name: 'courseDetail', params: { id: item.course_id_pri } }"
          >
            <v-tooltip
              color="#212121"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
                </v-btn>
              </template>
              {{ $t("detail") }}
            </v-tooltip>
          </router-link>

          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;item.course_type==1? isEditCourseLimitTimes = true:item.course_type==2?isEditCourseLimitDay=true:isEditCourseLimitDrug=true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t("edit") }}
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="course_id_pri = item.course_id_pri;course_status_id_update = item.course_status_id;isUpdateStatus = true"
              >
                <v-icon>{{ item.course_status_id==1? icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            {{ item.course_status_id==1?$t("suspend") : $t("normal") }}
          </v-tooltip>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data="options.page"
        :page-count="totalPage"
        :segment-id="+segmentId "
        :count-list="dataTableList.length"
        :total="+totalDataTableList"
        @pageChanged="(page)=>{options.page = page ; fetchDataTable()}"
        @itemChanged="(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}"
      />
    </v-card>
    <AddNewCourseList
      v-model="isAddNewCourse"
      @onAdd="fetchDataTable('add')"
    />
    <EditCourseLimitTimes
      v-model="isEditCourseLimitTimes"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <EditCourseLimitDay
      v-model="isEditCourseLimitDay"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <EditCourseLimitDrug
      v-model="isEditCourseLimitDrug"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon>  {{ course_status_id_update==1?$t('confirmCourseSuspend'):$t('cancelCourseSuspend') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="processStatus"
            :disabled="processStatus"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ImportFile
      v-model="isImportFile"
      :status-import="statusImport"
    />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiCheck,
  mdiAlertOutline,
  mdiFileImportOutline,
  mdiMenuDownOutline,
  mdiInformationOutline,
} from '@mdi/js'

import useCourseList from './useCourseList'
import { formatPrice } from '@/plugins/filters'
import CourseType from '@/components/basicComponents/CourseType.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import AddNewCourseList from './AddNewCourseList.vue'
import EditCourseLimitTimes from './EditCourseLimitTimes.vue'
import EditCourseLimitDay from './EditCourseLimitDay.vue'
import EditCourseLimitDrug from './EditCourseLimitDrug.vue'
import ImportFile from './ImportFile.vue'
import Pagination from '@/components/basicComponents/Pagination.vue'

export default {
  components: {
    CourseType,
    StatusBlock,
    AddNewCourseList,
    Pagination,
    EditCourseLimitTimes,
    EditCourseLimitDay,
    EditCourseLimitDrug,
    ImportFile,
  },
  filters: {
    formatPrice,
  },
  setup() {
    return {
      ...useCourseList(),
      icons: {
        mdiPlus,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiCheck,
        mdiAlertOutline,
        mdiFileImportOutline,
        mdiInformationOutline,
        mdiMenuDownOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

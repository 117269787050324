<template>
  <div>
    <v-dialog
      v-model="isAddNewCourse"
      persistent
      max-width="950"
    >
      <v-card>
        <v-card-title>
          {{ $t("add_course") }}
          <v-spacer></v-spacer>

          <v-radio-group
            v-model="course_type"
            class="mt-0"
            hide-details
            row
          >
            <v-radio
              :label="$t('limited_time')"
              value="1"
            ></v-radio>
            <v-radio
              :label="$t('limited_day')"
              value="2"
            ></v-radio>
            <v-radio
              :label="$t('limited_drug')"
              value="3"
            ></v-radio>
          </v-radio-group>
        </v-card-title>
        <v-form
          ref="formAddCourse"
          @submit.prevent="createCourse"
        >
          <v-card-text>
            <v-row class="p-0 m-0">
              <v-col cols="12" md=6 class="py-2 ">
                <v-autocomplete
                  v-model="coursegroup_id"
                  outlined
                  dense
                  :label="$t('course_category')"
                  :rules="[required]"
                  :items="coursegroup_data"
                  :no-data-text="$t('no_information')"
                  item-text="coursegroup_name"
                  item-value="coursegroup_id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md=6 class="py-2">
                <v-text-field
                  v-model="course_id"
                  :label="$t('code')"
                  :rules="[required]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md=6 class="py-2">
                <v-text-field
                  v-model="course_name"
                  :rules="[required]"
                  outlined
                  :label="$t('course_name')"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md=6 class="py-2 ">
                <v-text-field
                  v-model.trim="course_price"
                  type="number"
                  :label="$t('course_price')"
                  outlined
                  :rules="[required]"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md=6 class="py-2 ">
                <v-text-field
                  v-model.trim="course_costdoctor"
                  type="number"
                  outlined
                  dense
                  :label="$t('doctor_fee')"
                  :rules="[required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md=6 class="py-2">
                <v-text-field
                  v-model.trim="course_costuser"
                  type="number"
                  outlined
                  :label="$t('staff_costs')"
                  :rules="[required]"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6"
                v-if="course_type != 3"
                class="py-2 d-flex"
              >

                <v-text-field
                  v-if="course_type == 1"
                  v-model.trim="course_amount"
                  outlined
                  :label="course_type == 2?`${$t('end_date')}
                  $t('day')`:`${$t('times_courses')}
                  ${course_amount
                >
                  100?'ระบุจำนวนคอร์สสูงสุด 100 ครั้ง':''}`"
                  type="number"
                  style="width: 45%"
                  class='me-2'
                  :rules="[required]"
                  :placeholder="$t('enter_times_per_couse')"
                  dense
                  >
                </v-text-field>
                <v-text-field
                  v-if="course_type == 2 || course_type == 1"
                  v-model.trim="course_expday"
                  type="number"
                  style="width: 45%"
                  :label="$t('end_date')+$t('day')"
                  outlined
                  :rules="[required]"
                  dense
                  :placeholder="$t('enter_the_number_of_days')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" :md="course_type==3 ?'12':'6'"
                class="py-2"
              >
                <v-textarea
                  v-model.trim="course_detail"
                  outlined
                  dense
                  :label="$t('detail')"
                  rows="2"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="warning"
              @click="clearData"
              outlined
            >
              {{ $t("clear_data") }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || course_amount>100"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              color="secondary"
              @click="$emit('update:is-add-new-course',false)"
              outlined
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref,watch } from '@vue/composition-api'
import { required,textValidation } from '@/@core/utils/validation'
import courseGroup from "@/api/courseManagement/courseGroup"
import document from "@/api/systemSetting/document"
import courseList from "@/api/courseManagement/courseList"
import store from "@/store"

export default {
  model: {
    prop: 'isAddNewCourse',
    event: 'update:is-add-new-course',
  },
  props: {
    isAddNewCourse: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formAddCourse = ref(null)
    const course_type = ref('1')
    const coursegroup_data = ref([])
    const coursegroup_id = ref('')
    const course_id = ref('')
    const course_name = ref('')
    const course_price = ref('0')
    const course_costdoctor = ref('0')
    const course_costuser =  ref('0')
    const course_amount = ref('1')
    const course_expday = ref('0')
    const course_detail = ref('')

    const loading = ref(false)
    const {courseGroupList} = courseGroup
    const {documentSetting} = document
    const {courseAdd} = courseList

    const getDocs =()=>{
      documentSetting().then(res => {
        course_id.value =res.course
      })
    }
    const getCourseGroupList  = ()=>{
    courseGroupList({
      searchtext: '',
      coursegroup_status_id:1,
    }).then(res => {
      coursegroup_data.value = res
      coursegroup_id.value = res[0].coursegroup_id
    })
    }

    const clearData = () => {
       getDocs()
       getCourseGroupList()
      course_name.value = ''
      course_price.value = '0'
      course_costdoctor.value = '0'
      course_costuser.value = '0'
      course_amount.value = '1'
      course_expday.value = '0'
      course_detail.value = ''

    }



    const createCourse = () => {
      const isFormValid =formAddCourse.value.validate()
      if(!isFormValid) return
      loading.value = true
      courseAdd({
        course_id:course_id.value,
        course_name:course_name.value,
        course_price:course_price.value,
        course_costdoctor:course_costdoctor.value,
        course_costuser:course_costuser.value,
        course_amount:course_amount.value,
        course_expday:course_expday.value,
        course_detail:course_detail.value,
        coursegroup_id:coursegroup_id.value,
        course_type:course_type.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color:res.response ? 'success' : 'error',
        })
        emit('update:is-add-new-course', false)
        loading.value = false
        clearData()
        emit('onAdd')
      })
    }


    watch(()=>props.isAddNewCourse,()=>{        if(props.isAddNewCourse){
     clearData()
        }
    })

    watch([course_type],()=>{
       clearData()
    })

    return {
      formAddCourse,
      course_type,
      coursegroup_data,
      coursegroup_id,
      course_id,
      course_name,
      course_price,
      course_costdoctor,
      course_costuser,
      course_amount,
      course_expday,
      course_detail,
      required,
      textValidation,
      createCourse,
      loading,
      clearData,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <v-dialog
      v-model="isEditCourseLimitDay"
      persistent
      max-width="950"
    >
      <v-card>
        <v-card-title>
          {{ $t('edit_course') }} {{ title }} <CourseType
            class="ml-2"
            :type="dataEditLocal.course_type"
          />
        </v-card-title>
        <v-form
          ref="formEditCourse"
          @submit.prevent="updateCourse"
        >
          <v-card-text class="mt-2">
            <v-row class="p-0 m-0">
              <v-col
                cols="12"
                md="6"
                class="py-2 "
              >
                <v-autocomplete
                  v-model="dataEditLocal.coursegroup_id"
                  outlined
                  dense
                  :label="$t('course_category')"
                  :rules="[required]"
                  :items="coursegroup_data"
                  :no-data-text="$t('no_data')"
                  item-text="coursegroup_name"
                  item-value="coursegroup_id"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2"
              >
                <v-text-field
                  v-model="dataEditLocal.course_id"
                  :label="$t('code')"
                  :rules="[required]"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2"
              >
                <v-text-field
                  v-model="dataEditLocal.course_name"
                  :rules="[required]"
                  outlined
                  :label="$t('course_name')"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2 "
              >
                <v-text-field
                  v-model="dataEditLocal.course_price"
                  type="number"
                  :label="$t('course_price')"
                  outlined
                  :rules="[required]"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2 "
              >
                <v-text-field
                  v-model="dataEditLocal.course_costdoctor"
                  type="number"
                  outlined
                  dense
                  :label="$t('doctor_fee')"
                  :rules="[required]"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2"
              >
                <v-text-field
                  v-model="dataEditLocal.course_costuser"
                  type="number"
                  outlined
                  :label="$t('staff_costs')"
                  :rules="[required]"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2 "
              >
                <v-text-field
                  v-model="dataEditLocal.course_expday"
                  outlined
                  hide-details
                  :label="$t('end_date')+$t('day')"
                  type="number"
                  :rules="[required]"
                  :placeholder="$t('enter_the_number_of_days')"
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
                class="py-2"
              >
                <v-textarea
                  v-model="dataEditLocal.course_detail"
                  outlined
                  dense
                  :label="$t('detail')"
                  rows="2"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn
              color="primary"
              type="submit"
              :loading="loading"
              :disabled="loading || dataEditLocal.course_amount>100"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="$emit('update:is-edit-course-limit-day',false)"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import courseList from '@/api/courseManagement/courseList'
import courseGroup from '@/api/courseManagement/courseGroup'
import { required, textValidation } from '@/@core/utils/validation'
import CourseType from '@/components/basicComponents/CourseType.vue'

export default {
  components: {
    CourseType,
  },
  model: {
    prop: 'isEditCourseLimitDay',
    event: 'update:is-edit-course-limit-day',
  },
  props: {
    isEditCourseLimitDay: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },

  setup(props, { emit }) {
    const formEditCourse = ref(null)
    const dataEditLocal = ref({})
    const coursegroup_data = ref([])
    const title = ref('')
    const loading = ref(false)

    const { courseUpdate } = courseList
    const { courseGroupList } = courseGroup
    const getCourseGroupList = () => {
      courseGroupList({
        searchtext: '',
        coursegroup_status_id: 1,
      }).then(res => {
        coursegroup_data.value = res
      })
    }

    const updateCourse = () => {
      const isFormValid = formEditCourse.value.validate()
      if (!isFormValid) return
      loading.value = true
      courseUpdate(dataEditLocal.value).then(res => {
        emit('update:is-edit-course-limit-day', false)
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        loading.value = false
        emit('onUpdate')
      })
    }

    watch(() => props.dataEdit, newVal => {
      getCourseGroupList()
      title.value = newVal.course_name
      dataEditLocal.value = JSON.parse(JSON.stringify(newVal))
    })

    return {
      title,
      formEditCourse,
      dataEditLocal,
      coursegroup_data,
      loading,
      updateCourse,
      required,
      textValidation,
    }
  },
}
</script>
